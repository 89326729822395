<template>
  <div class="container">
   
<div class="d-flex align-items-center justify-content-center .gif-container" v-if="showGif" style="position: fixed; top: 0; left: 0; right: 0; bottom: 0;">
  <!-- Display your GIF here -->
  <img src="../public/images/golnoor.gif" class="img-fluid animlogo" alt="Loading GIF" loop="false" style="width: 80%;" />
  
</div>

    <div class="row justify-content-center" v-else-if="currentView === 'categories'">
      <div class="logobg row d-flex" style="z-index: 1;">
        <img src="../public/images/unnamed.png" class="img-fluid col-md-2 col-4 mt-3 mb-1 offset-4 offset-md-5" alt="">
      </div>
      <div class="col-12 d-flex justify-content-center align-items-center cate b1 mt-4" style="height: 100%; z-index: 0;">
        <CategoryList :categories="categories" @show-products="showProducts" />
      </div>
      <footer class=" text-center mb-3">
       <div class="container">
           <p class="mt-5">
              Powered by <a href="https://www.ariisco.com" style="color: var(--main-color);  text-decoration: none;">Ariis</a>
           </p>
       </div>
    </footer>
    </div>
    <div class="row" style="overflow: hidden" v-else-if="currentView === 'products'">
      <div class="  d-flex justify-content-center" >
       
        <div class=" row  ">
            <button v-if="currentView !== 'categories'" @click="showCategories" class="col-4 btn  d-flex ">
          <i class="bi  bi-arrow-left iconcolor align-items-center fs-1 " ></i></button>
          <img src="../public/images/unnamed.png" class="img-fluid col-md-3 col-4 mt-3 mb-1  "  alt="">
        </div>
        

      </div>

      <div class="d-flex">

        
      </div>


      <div class="col-12" style="height: 50vh; margin-left: 5%;">
        <model-viewer
          v-if="modelVisible"
          :src="modelSrc"
          :ios-src="iosSrc"
          :poster="poster"
          :alt="alt"
          ar-scale="fixed"
          shadow-intensity="1.5"
          camera-controls
          auto-rotate
          ar
          xr-environment
          style="height: 90%; width: 90%;"
        >
          <template v-slot:ar-button>
            <button id="ar-button" @click="startAR" class="btn justify-content-center sam">View in your space</button>
          </template>
        </model-viewer>
      </div>

      <div class="col-12" style="height: 25vh;">
        <div class="d-flex overflow-auto" style="max-height: 100%;">
          <ProductList :products="selectedCategory.products" @show-model="showModel" />
        </div>
      </div>
      <footer class=" text-center mb-3">
       <div class="container">
           <p class="mb-0">
              Powered by <a href="https://www.ariisco.com" style="color: var(--main-color);  text-decoration: none;">Ariis</a>
           </p>
       </div>
    </footer>
    </div>

  </div>

</template>

<script>
import api from './api';
import CategoryList from './components/CategoryList';
import ProductList from './components/ProductList';
import 'bootstrap/dist/css/bootstrap.css';


export default {
  data() {
    return {
      categories: [],
      selectedCategory: {
        products: [],
      },
      modelVisible: false,
      modelSrc: '',
      iosSrc: '',
      poster: '',
      alt: '',
      currentView: 'loading', // Initially set to 'loading'
      showGif: true, // Initially show the GIF
    };
  },
  components: {
    CategoryList,
    ProductList,
  },
  methods: {
    fetchCategories() {
      api.getCategories().then((response) => {
        this.categories = response.data.data;
      });
    },
    showCategories() {
      this.currentView = 'categories';
    },
    showProducts(category) {
      this.selectedCategory = category;
      this.modelVisible = true; // Show the model by default when going to the 'products' view
      this.modelSrc = `https://xrpanel.ariisco.com/assets/${category.products[0].glbModel}`;
      this.iosSrc = `https://xrpanel.ariisco.com/assets/${category.products[0].usdzModel}`;
      this.poster = `https://xrpanel.ariisco.com/assets/${category.products[0].posterImage}`;
      this.alt = category.products[0].name;
      this.currentView = 'products';
    },
    showModel(product) {
      this.modelVisible = true;
      this.modelSrc = `https://xrpanel.ariisco.com/assets/${product.glbModel}`;
      this.iosSrc = `https://xrpanel.ariisco.com/assets/${product.usdzModel}`;
      this.poster = `https://xrpanel.ariisco.com/assets/${product.posterImage}`;
      this.alt = product.name;
    },
    startAR() {
      if ('xr' in navigator) {
        navigator.xr
          .requestSession('immersive-ar')
          .then((session) => {
            const modelViewer = this.$refs.modelViewer;
            session.requestReferenceSpace('viewer').then((referenceSpace) => {
              modelViewer.activateAR(session, referenceSpace);
            });
          })
          .catch((error) => {
            console.error('Failed to start AR:', error);
          });
      } else {
        console.error('WebXR AR mode is not supported in this browser.');
      }
    },
    // Hide the GIF after 6 seconds and show categories
    hideGifAndShowCategories() {
      setTimeout(() => {
        this.showGif = false;
        this.currentView = 'categories';
      }, 4000); // 6000 milliseconds (6 seconds)
    },
  },
  created() {
    this.fetchCategories();
    this.hideGifAndShowCategories(); // Hide the GIF and show categories after 6 seconds
  },
};
</script>


<style>

:root {
  --main-color:#00ADC6;
}

@media(min-width:768px) {
.animlogo{
  width: 30%;
}

/* .logo {
  width: 10%;
  margin-left: 0px;
} */

.logobg{
  background-color: white;
  /* width: 100%;
  height: 10%; */
  position: fixed;
  z-index: 0;
}



.backbtn {
  color: var(--main-color);
}

.bigscreenlogo {
  width: 25%;
}







}

@media(max-width:768px) {
  .animlogo{
    margin-top: 20%;
    width: 50%;
  }

  /* .logo {
  width: 30%;
  /* margin-left: 8%; */
  /* margin-top: 20px; */
  
} 



.logobg{
  background-color: white;

  position: fixed;
  z-index: 0;
}

.backbtn {
  color: var(--main-color);

}

.gif-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
}

.iconcolor {
  color: #00ADC6;
}


model-viewer::part(default-ar-button) {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Additional styles if needed */
}




</style>