// src/api.js

import axios from 'axios';

const api = axios.create({
  baseURL: 'https://xrpanel.ariisco.com', // Replace with your API endpoint
});

export default {
  getCategories() {
    return api.get('/items/categories/?fields=*,products.*&filter[factoryId]=1');
  },
};
