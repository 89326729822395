<template>
  <div class="product-list">
    <div v-for="product in products" :key="product.id" @click="showModel(product)" :class="{ 'product-item': true, 'pimgborder': true, 'selected': product.selected} " style="height: 100%;">
      <img :src="getImageURL(product.posterImage)" :alt="product.name" class=""/>
      <div class="product-name fw-bold ">{{ product.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['products'],
  methods: {
    getImageURL(imageId) {
      return `https://xrpanel.ariisco.com/assets/${imageId}.jpg`;
    },
    showModel(product) {
      // Deselect all products first
      this.products.forEach((p) => {
        p.selected = false;
      });
      // Select the clicked product
      product.selected = true;
      this.$emit('show-model', product);
    },
  },
};
</script>

<style scoped>
:root {
  --main-color: #00ADC6;
}

.product-list {
  display: flex;
  flex-wrap: nowrap;
}



.product-item img {
  width: 50%;
  height: 60%;
}

.product-name {
  margin-top: 5px;
}

.pimgborder {
  border: 2px solid var(--main-color);
  border-radius: 20px;
}

.product-item.selected {
  /* background-color: rgba(0, 173, 198, 0.3); */
  background-color: #00ADC6;
  border: 2px solid #00ADC6;
  color: white;
}

@media (max-width: 768px) {
  .product-item {
  flex: 0 0 auto;
  margin-right: 10px;
  text-align: center;
  width: 60vw;
}
}

@media (min-width: 768px) {
  .product-item {
  flex: 0 0 auto;
  margin-right: 10px;
  text-align: center;
  width: 25vw;
}
}


</style>
