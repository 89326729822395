<template>
  <div class="text-center w-100 mt-5">
    <div
      v-for="category in categories"
      @click="showProducts(category)"
      :key="category.id"
      class="mb-4"
      style="cursor: pointer;"
    >
      <div class="d-flex flex-md-row align-items-center justify-content-around bcolor rounded-3 p-4 mt-4">
        <div class="productimg me-5">
          <img
            :src="'https://xrpanel.ariisco.com/assets/' + category.image"
            alt="Category Image"
            class="img-fluid singleimg"
          />
        </div>
        <div class="fw-bold fs-6 ">{{ category.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['categories'],
  methods: {
    showProducts(category) {
      // Deselect all products in all categories
      this.categories.forEach((c) => {
        if (c.products) {
          c.products.forEach((p) => {
            p.selected = false;
          });
        }
      });

      // Emit the show-products event to notify the parent component
      this.$emit('show-products', category);

      // Select the first product in the category
      if (category.products && category.products.length > 0) {
        category.products[0].selected = true;
      }
    },
  },
};
</script>

<style>
:root {
  --main-color: #00ADC6;
}

@media (max-width: 768px) {
  .productimg {
    width: 40%;
    /* height: 18vh; */
  }

}

@media (min-width: 768px) {
  .productimg {
    width: 40%;
    /* height: 30%; */
  }
  .singleimg {
    width: 50%;
  }
}

.bcolor {
  /* border: 2px solid #00ADC6; */
  box-shadow: 5px 5px 16px lightblue;
  background-color: #00adc6;
  color: white;

}

/* Add a class for selected products */
.selected {
  border: 2px solid black;
}
</style>
